<div class="cabecera-modal">
  SELECTOR DE SOLICITUDES DE DVS VENCIDOS SIN TOTAL DE PUNTOS
  <mat-icon class="cerrar-modal" (click)="cerrar()">close</mat-icon>
</div>

<ion-content class="ion-padding">
  <div *ngIf="appService.pedidosDVSinIndicarPuntosConFechaDeContactoSuperiorAHoy.length === 0">
    <ion-item>
      <h3 style="margin: auto">
        <mat-icon>warning</mat-icon> ¡No hay pedidos de DV sin puntos que su fecha de contacto o fecha de cita sea superior a ahora!
      </h3>
    </ion-item>
  </div>

  <div *ngFor="let pedido of appService.pedidosDVSinIndicarPuntosConFechaDeContactoSuperiorAHoy">
    <ion-item>
      <ion-label class="ion-text-wrap">
        <h2 class="d-flex">
          <mat-icon class="ion-margin-end">new_releases</mat-icon>
          <span style="margin-right: 30px">Fecha cita: <span style="color: orange">{{pedido.fechaContacto | date:'dd/MM/yyyy-HH:mm'}}</span></span>
          <span style="margin-right: 30px"> Referencia: {{pedido.referencia}} </span>
          <span style="margin-right: 30px"> Diseñador: {{(pedido.disenado.nombre + ' ' + pedido.disenado.apellidos) | titlecase}} </span>
          <span style="margin-left: auto"
            ><button title="Ir al pedido" (click)="solicitarDV(pedido)" style="background-color: white">
              <img src="assets/imgs/iconosMenu/totalPuntos.png" alt="" style="height: 30px" /></button
          ></span>
        </h2>
        <p>
          El solicitante con perfil TECLA: {{(pedido?.usuarioTCLA != undefined ? pedido?.usuarioTCLA : 'Desconocido')}}
          {{(pedido.solicitudDVNumeroWhatsApp? '| Num. Whatsapp: ' + pedido.solicitudDVNumeroWhatsApp : '')}}
        </p>
      </ion-label>
    </ion-item>
  </div>
</ion-content>

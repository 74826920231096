class Contacto {
  public _id: string;
  public nombre: string;
  public cp: string;
  public email: string;
  public telefono: string;

  constructor (c: any = null) {
    if (c != null) {
      this._id = c._id;
      this.nombre = c.nombre;
      this.cp = c.cp;
      this.email = c.email;
      this.telefono = c.telefono;
    }
  }
}
export class SolicitudDV {
  // public _id: string;
  // public anulada: boolean;
  // public finalizada: boolean;
  // public idCliente: Contacto;
  // public idTienda: string;
  // public referencia: string;
  // public fechaContacto: Date;
  // public fechacreacion: Date;
  // public fechamodificacion: Date;
  // public observaciones: string[];
  public _id: string;
	public anulada : {type: Boolean, default: false};
	public finalizada : {type: Boolean, default: false};
	public idCliente: Contacto;
	public idTienda: string;
  public referencia: String;
	public fechacontacto: Date;
	public fechacreacion: Date;
	public fechamodificacion: Date;
	public observaciones : string[];
	public identificadorPedido: String;
	public turnoAccionTienda : boolean;
	public notificarTurnoAccionTienda : boolean;
	public notificarComentarioTienda : boolean;
	public prioridad: String;
	public trackingEstados: [{
    fecha: Date,
		estado: String,
		observaciones: String,
	}];
	public trackingCitas: [{
    fecha: Date,
		objetivo: String,
		observaciones: String,
	}];
	public archivos: string[];
	public estado: string;
  public idCRM: string;
  


  constructor(s: any = null) {
    if (s != null) {
      this._id = s._id;
      this.anulada = s.anulada;
      this.finalizada = s.finalizada;
      this.idCliente = new Contacto(s.idCliente);
      this.idTienda = s.idTienda != null ? s.idTienda._id : '';
      this.referencia = s.referencia;
      this.fechacontacto = s.fechacontacto != null ? new Date(s.fechacontacto) : new Date();
      this.fechacreacion = s.fechacreacion != null ? new Date(s.fechacreacion) : new Date();
      this.fechamodificacion = s.fechamodificacion != null ? new Date(s.fechamodificacion) : new Date();
      this.observaciones = s.observaciones || [];
      this.identificadorPedido = s.identificadorPedido;
      this.turnoAccionTienda = typeof(s.turnoAccionTienda) === 'undefined' ? false : s.turnoAccionTienda ;
      this.notificarTurnoAccionTienda = typeof(s.notificarTurnoAccionTienda) === 'undefined' ? false : s.notificarTurnoAccionTienda ;
      this.notificarComentarioTienda = typeof(s.notificarComentarioTienda) === 'undefined' ? false : s.notificarComentarioTienda ;
      this.prioridad = s.prioridad
      this.trackingEstados = s.trackingEstados;
      this.trackingCitas = s.trackingCitas;
      this.archivos = s.archivos;
      this.estado = s.estado;
      this.idCRM = s.idCRM;
    }
  }
}

import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {SessionService} from './SessionService';
import {catchError} from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private alertDisplay: boolean;

  constructor(private sessionService: SessionService) {
    this.alertDisplay = false;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token: string = this.sessionService.getToken();

    let request = req;

    if (token) {
      request = req.clone({
        setHeaders: {
          authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (!this.alertDisplay && (err.status === 401 || err.status === 403)) {
          this.alertDisplay = true;
          alert('La sesión ha caducado. Pulsa Aceptar para ir al Login');

          this.sessionService.removeToken();
          location.reload();
        }
        return throwError(err);
      })
    );
  }
}

<div id="notificacion" *ngIf="mostrar" class="notificacion">
  <div (click)="ocultarNotificacion(); abrirSelectorDV();" style="cursor: pointer">
    <div style="display: inline-block; width: 32px; height: 32px; margin-right: 8px">
      <img src="assets/imgs/iconosMenu/disenadorvirtual.png" alt="" style="height: 100%; width: 100%" />
    </div>
    <div style="display: inline-block; width: calc(100% - 40px)">HAY {{appService.numPedidosDV}} SOLICITUDES DE DISEÑADOR VIRTUAL PENDIENTES</div>
  </div>
  <mat-icon style="position: absolute; right: -5px; top: 3px; cursor: pointer; font-size: 14px; color: gray" (click)="ocultarNotificacion();"
    >close</mat-icon
  >
</div>

<div id="notificacionPedidosDVSinTotalPuntos" *ngIf="mostrarPedidosDVSinTotalPuntos" class="notificacionPedidosDVSinTotalPuntos">
  <div (click)="ocultarNotificacionPedidoDVSinPuntos(); abrirSelectorDVPedidoDVSinPuntos();" style="cursor: pointer">
    <div style="display: inline-block; width: 32px; height: 32px; margin-right: 8px">
      <img src="assets/imgs/iconosMenu/totalPuntos.png" alt="" style="height: 100%; width: 100%" />
    </div>
    <div style="display: inline-block; width: calc(100% - 40px)">
      HAY {{appService.pedidosDVSinIndicarPuntosConFechaDeContactoSuperiorAHoy.length}} PEDIDOS DE DV VENCIDOS PENDIENTES DE ASIGNAR EL TOTAL DE
      PUNTOS
    </div>
  </div>
  <mat-icon
    style="position: absolute; right: -5px; top: 3px; cursor: pointer; font-size: 14px; color: gray"
    (click)="ocultarNotificacionPedidoDVSinPuntos();"
    >close</mat-icon
  >
</div>

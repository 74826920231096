import {Validators} from '@angular/forms';

interface Control {
  id: string;
  placeholder?: string;
  validators: Validators[];
  value?: any;
}
interface Input extends Control {
  type?: 'text' | 'number' | 'date' | 'password' | 'textarea';
}
interface Select extends Control {
  options: {text: string, value: any}[];
  multiple?: boolean;
}

export class DialogConfig {
  public textoPrincipal: string;
  public textoSecundario?: {texto: string, css?: string}[];
  public controls?: (Input|Select) [];
  public btnConfirm?: string;
  public btnCancel?: string;

  public constructor(c: any = null) {
    if (c != null) {
      this.textoPrincipal = c.textoPrincipal;
      this.textoSecundario = c.textoSecundario;
      this.controls = c.controls;
      this.btnConfirm = c.btnConfirm;
      this.btnCancel = c.btnCancel;
    }
  }
}

export type Rol = 'asesor' | 'comercial' | 'pedidos' | 'supervisor' | 'admin';

export class Usuario {
  public nombreUsuario: string;
  public password: string;
  public rol: Rol;
  public team: string;
  public nombre: string;
  public apellidos: string;
  public email: string;
  public telefono: string;
  public poblacion: string;
  public provincia: string;
  public imagen: string;
  public desactivado: boolean;
  public nuevo: boolean;
  public codigoRepresentante: [string];
  public _id: string;
  public fiabilidad: number;
  public aprendizaje: boolean;
  public usuarioTienda: boolean;
  public funcionAsesor: string;
  public correoDisenadorVirtual: string;

  public constructor(u: any = null) {
    if (u != null) {
      this.nombreUsuario = u.nombreUsuario;
      this.password = u.password;
      this.rol = u.rol;
      this.team = u.team;
      this.nombre = u.nombre;
      this.apellidos = u.apellidos;
      this.email = u.email;
      this.telefono = u.telefono;
      this.poblacion = u.poblacion;
      this.provincia = u.provincia;
      this.imagen = u.imagen;
      this.desactivado = u.desactivado;
      this.nuevo = u.nuevo;
      this.codigoRepresentante = u.codigoRepresentante;
      this._id = u._id;
      this.fiabilidad = u.fiabilidad;
      this.aprendizaje = u.aprendizaje;
      this.usuarioTienda = u.usuarioTienda;
      this.funcionAsesor = u.funcionAsesor;
      this.correoDisenadorVirtual = u.correoDisenadorVirtual;
    }
  }

  public get nombreApellidos(): string {
    return this.nombre + ' ' + this.apellidos;
  }
  public get esUsuarioGestor(): boolean {
    if (this.rol !== 'comercial') {
      return true;
    } else {
      return this.codigoRepresentante != null && this.codigoRepresentante.length > 0;
    }
  }
  public get funcion(): string {
    if (this.rol === 'asesor') {
      if (this.aprendizaje) {
        return 'PERFIL: EN APRENDIZAJE';
      } else {
        switch (this.funcionAsesor) {
          case 'traducir':
            return 'PERFIL: TRADUCTOR';
          case 'revisar':
            return 'PERFIL: REVISOR';
          case 'traducirRevisar':
            return 'PERFIL: TRADUCTOR Y REVISOR';
          default:
            return '';
        }
      }
    } else {
      return '';
    }
  }
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType } from "@angular/common/http";
import { Subscription } from "rxjs";
import { Pedido } from "../modelos/Pedido";

type Status = "PENDIENTE" | "DESCARGANDO" | "TERMINADA" | "INTERRUMPIDA" | "ELIMINADA";

class ObjectURL {
  private _data: string | null; // Objeto a descargar
  private readonly _expirationTime: number; // Momento en el que expira el objeto

  constructor(data: Blob) {
    this._data = window.URL.createObjectURL(new Blob([data], { type: data.type }));
    this._expirationTime = Date.now() + 5 * 60 * 1000;
  }

  public hasExpired(): boolean {
    // Indica si el objeto ha expirado.
    return this._expirationTime < Date.now();
  }
  public dataToDownloadLink(fileName: string): void {
    // Descarga el objeto.
    const downloadLink = document.createElement("a");
    downloadLink.href = this._data;
    downloadLink.setAttribute("download", fileName);

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  public destroy(): void {
    // Destruye el objeto.
    if (this._data != null) {
      window.URL.revokeObjectURL(this._data);
      this._data = null;
    }
  }
}

export class Download {
  private readonly _id: string; // ID único de la descarga.
  private readonly _file: string; // Nombre del fichero.
  private readonly _url: string; // URL completa a la que se va a solicitar el recurso.
  private _request?: Subscription | null; // Petición del recurso.
  private _loaded: number; // Bytes que se han descargado.
  private _total: number; // Bytes totales.
  private _estado: Status; // Estado de la descarga.
  private _object: ObjectURL | null; // Objeto descargado.

  constructor(nId: string, nFile: string, nUrl: string, nLoaded: number, nTotal: number, nEstado: Status, private http: HttpClient) {
    this._id = nId;
    this._file = nFile;
    this._url = nUrl;
    this._loaded = nLoaded;
    this._total = nTotal;
    this._estado = nEstado;
    this._request = null;
    this._object = null;
  }

  public startDownload(): void {
    // Si no se ha descargado aún, se solicita el recurso.
    // Si no, se utliza el objeto ya descargado.
    if (this._object == null) {
      this._request = null;
      this._loaded = 0;
      this._total = 0;
      this._estado = "PENDIENTE";
      this._downloadFile();
    } else {
      this._object.dataToDownloadLink(this._file);
    }
  }
  public stopDownload(): void {
    // Se detiene la descarga del recurso.
    if (this._estado === "DESCARGANDO" && this._request != null) {
      this._request.unsubscribe();
      this._estado = "INTERRUMPIDA";
    }
  }
  public removeDownload(): void {
    // Se elimina el objeto y la descarga se marca como eliminada.
    this.removeObjectURL();
    this._estado = "ELIMINADA";
  }
  public removeObjectURL(): void {
    if (this._object != null) {
      this._object.destroy();
      this._object = null;
    }
  }

  public get id(): string {
    return this._id;
  }
  public get file(): string {
    return this._file;
  }
  public get estado(): Status {
    return this._estado;
  }
  public get loaded(): number {
    return this._loaded;
  }
  public get total(): number {
    return this._total;
  }
  public get loadedTr(): string {
    return this.magnitud === "KB" ? this._truncar(this._loaded / 1000, 2) : this._truncar(this._loaded / 1000000, 2);
  }
  public get totalTr(): string {
    return this.magnitud === "KB" ? this._truncar(this._total / 1000, 2) : this._truncar(this._total / 1000000, 2);
  }
  public get magnitud(): "KB" | "MB" {
    return this._total < 9999 ? "KB" : "MB";
  }
  public get objectHasExpired(): boolean {
    return this._object != null && this._object.hasExpired();
  }
  public get hasObject(): boolean {
    return this._object != null;
  }

  private _downloadFile(): void {
    this._request = this.http
      .get(this._url, { reportProgress: true, observe: "events", responseType: "blob" as "json" })
      .subscribe((response: any) => {
        if (response.type === HttpEventType.DownloadProgress) {
          this._estado = "DESCARGANDO";
          this._loaded = response.loaded;
          this._total = response.total;
        }

        if (response.type === HttpEventType.Response) {
          this._object = new ObjectURL(response.body);
          this._object.dataToDownloadLink(this._file);
          this._estado = "TERMINADA";
        }
      });
  }

  private _truncar(n: number, l: number = 2): string {
    return (
      n.toString(10).split(".")[0] + // INT
      (n.toString(10).split(".")[1] == null ? "" : "," + n.toString(10).split(".")[1].substr(0, l))
    ); // FLOAT
  }
}

@Injectable({
  providedIn: "root",
})
export class DownloadService {
  private readonly urlServer: string;
  private readonly urlServerBalder: string;

  private _n: number;
  private _downloads: Download[];
  private _mostrarGestorDescargas: boolean;

  constructor(private http: HttpClient) {
    //Para desarrollo en local
    // this.urlServer = "http://localhost:3008";
    // this.urlServerBalder = 'http://localhost:3010';

    //Para produccion
    this.urlServer = "https://mueblesdormacrm.com:3008";
    this.urlServerBalder = "https://mueblesdormacrm.com:3010";

    this._n = 0;
    this._downloads = [];
    this._mostrarGestorDescargas = false;

    setInterval(() => {
      for (const download of this._downloads.filter((e) => e.objectHasExpired)) {
        download.removeObjectURL();
      }
    }, 60 * 1000);
  }

  public descargarArchivoPedidos(archivo: string): void {
    this._iniDownload(this._newDownload(archivo, this.urlServer + "/pedidos/descargarArchivo/" + archivo));
  }
  public descargarPedido(pedido: Pedido): void {
    const fileName = pedido.referencia != null && pedido.referencia.trim().length ? pedido.referencia + ".zip" : pedido._id + ".zip";
    this._iniDownload(this._newDownload(fileName, this.urlServer + "/pedidos/descargarPedido/" + pedido._id));
  }
  public descargarIncidencia(archivo: string): void {
    this._iniDownload(this._newDownload(archivo + ".pdf", this.urlServer + "/pedidos/descargarIncidencia/" + archivo));
  }
  public descargarPackageList(archivo: string): void {
    this._iniDownload(this._newDownload(archivo, this.urlServerBalder + "/descargas/descargarPackageList/" + archivo));
  }

  public get numDescargando(): number {
    return this._downloads.filter((e) => e.estado === "PENDIENTE" || e.estado === "DESCARGANDO").length;
  }
  public get downloads(): Download[] {
    return this._downloads.filter((e) => e.estado !== "ELIMINADA").reverse();
  }
  public get mostrarGestorDescargas(): boolean {
    return this._mostrarGestorDescargas;
  }
  public set mostrarGestorDescargas(value: boolean) {
    this._mostrarGestorDescargas = value;
  }

  public getDownloadById(id: string): Download | null {
    return this._downloads.find((e) => e.id === id);
  }

  private _iniDownload(download: Download): void {
    this._mostrarGestorDescargas = true;
    this._addDownload(download);
    download.startDownload();
  }
  private _newDownload(file: string, url: string): Download {
    return new Download(this._n++ + "_" + file, file, url, 0, 0, "PENDIENTE", this.http);
  }
  private _addDownload(download: Download): void {
    if (!this._downloads.some((e) => e.id === download.id)) {
      this._downloads.push(download);
    }
  }
}

import {Injectable} from '@angular/core';

@Injectable()
export class SessionService {
  constructor() {}

  public getToken(): string {
    return localStorage.getItem('access_token');
  }
  public setToken(token: string): void {
    localStorage.setItem('access_token', token);
  }
  public removeToken(): void {
    localStorage.removeItem('access_token');
  }
}

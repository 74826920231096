<div *ngIf="!ds.mostrarGestorDescargas" role="button" class="btn-descargas" (click)="abrirDescargas()">
  <div class="badge" *ngIf="ds.numDescargando > 0">{{ds.numDescargando}}</div>
  <ion-icon name="cloud-download-sharp" class="icono-btn-descargas"></ion-icon>
</div>

<div *ngIf="ds.mostrarGestorDescargas" class="user-select-none">
  <div class="cabecera">
    Descargas
    <mat-icon class="btn-cerrar" (click)="cerrarDescargas()">expand_more</mat-icon>
  </div>

  <div class="contenido">
    <div *ngFor="let download of ds.downloads" class="descarga-container" id="{{download.id}}"
         (mouseenter)="setDownloadOver(download.id)" (mouseleave)="setDownloadOver(null)">

      <div class="icono-fichero" [matMenuTriggerFor]="menuDownload" [matMenuTriggerData]="{download: download}">
        <ion-icon class="btn-icono-fichero" name="{{getIconoDownload(download)}}" color="primary"></ion-icon>
      </div>
      <div class="nombre-fichero" matTooltip="{{download.file}}">
        <span>{{download.file}}</span>
        <ion-progress-bar *ngIf="mostrarProgressBar(download)" class="progress-bar"
                          [value]="getValueProgressBar(download)" [color]="getColorProgressBar(download)"></ion-progress-bar>
      </div>
      <div class="tam-fichero">
        <span><span *ngIf="download.loaded != download.total">{{download.loadedTr}} / </span>{{download.totalTr}} {{download.magnitud}} {{download.estado}}</span>
      </div>

    </div>
  </div>
</div>

<mat-menu #menuDownload='matMenu' xPosition="after">
  <ng-template matMenuContent let-download="download">
    <button mat-menu-item *ngIf="download.estado === 'PENDIENTE' || download.estado === 'DESCARGANDO'" (click)="detenerDescarga(download)">
      <mat-icon>stop_circle</mat-icon>
      <span>Detener</span>
    </button>
    <button mat-menu-item *ngIf="download.estado === 'INTERRUMPIDA' || download.estado === 'TERMINADA'" (click)="reiniciarDescarga(download)">
      <mat-icon>refresh</mat-icon>
      <span>{{download.estado === 'INTERRUMPIDA' ? 'Reiniciar' : 'Volver a Descargar'}}</span>
    </button>
    <button mat-menu-item *ngIf="download.estado === 'INTERRUMPIDA' || download.estado === 'TERMINADA'" (click)="eliminarDescarga(download)">
      <mat-icon>delete</mat-icon>
      <span>Eliminar</span>
    </button>
  </ng-template>
</mat-menu>

<div class="cabecera-modal">
  SELECTOR DE SOLICITUDES DE DISEÑO VIRTUAL
  <mat-icon class="cerrar-modal" (click)="cerrar()">close</mat-icon>
</div>

<div *ngIf="selectorDeConversacionesActivado" style="height: 100%; width: 100%; background-color: #00000060; position: fixed; z-index: 9999999999999">
  <div style="background-color: white; height: 100%; width: 100%; overflow: auto">
    <div style="display: flex; justify-content: space-between; position: sticky; top: 0; background-color: white">
      <div style="display: flex; justify-content: start; padding: 12px">
        <button
          (click)="selectorDeConversacionesActivado = false; resultadoBuscador = ''"
          style="padding: 12px 6px; box-shadow: 1px 1px 3px 0 gray; border-radius: 20px; background-color: #428bca; color: white"
        >
          Volver
        </button>
      </div>
    </div>
    <div style="padding: 16px; font-weight: 700; font-size: 20px" *ngIf="conversacionesTelefonicasDelCliente.length === 0">
      No hay conversaciones para seleccionar a este cliente
    </div>

    <div
      *ngFor="let conversacion of conversacionesTelefonicasDelCliente"
      style="display: flex; justify-content: space-between; border-bottom: 1px solid black; row-gap: 12px; padding: 12px 24px"
    >
      <div style="display: flex; flex-direction: column; width: 19%; row-gap: 8px">
        <div style="align-items: center"><strong>Nombre cliente:</strong></div>
        <div>{{ conversacion.nombreContactoCliente ? conversacion.nombreContactoCliente : ''}}</div>
      </div>

      <div style="display: flex; flex-direction: column; width: 19%; row-gap: 8px">
        <div style="align-items: center"><strong>Provincia:</strong></div>
        <div>{{ conversacion.provincia ? conversacion.provincia : ''}}</div>
      </div>

      <div style="display: flex; flex-direction: column; width: 19%; row-gap: 8px">
        <div style="align-items: center"><strong>Último Comentario:</strong></div>
        <div style="height: 40px; overflow: hidden">
          {{ conversacion.conversaciones.length > 0 ? conversacion.conversaciones[conversacion.conversaciones.length - 1].contenido : '' }}
        </div>
      </div>

      <div style="display: flex; flex-direction: column; width: 19%; row-gap: 8px">
        <div style="align-items: center"><strong>Creación conversación:</strong></div>
        <div>{{ conversacion.fechaCreacion ? (conversacion.fechaCreacion | date: 'dd/MM/yyyy') : ''}}</div>
      </div>

      <button
        (click)="enlazarConversacionConPedido(conversacion)"
        style="margin: 12px 6px; box-shadow: 1px 1px 3px 0 gray; border-radius: 20px; background-color: #428bca; color: white; padding: 6px 12px"
      >
        Enlazar
      </button>
    </div>
  </div>
</div>

<ion-content class="ion-padding">
  <div *ngIf="appService.pedidosDVenCache.length === 0">
    <ion-item>
      <h3 style="margin: auto"><mat-icon>warning</mat-icon> ¡No hay solicitudes virtuales pendientes!</h3>
    </ion-item>
  </div>

  <div *ngFor="let pedido of appService.pedidosDVenCache">
    <ion-item>
      <ion-label class="ion-text-wrap">
        <h2 class="d-flex">
          <mat-icon class="ion-margin-end">new_releases</mat-icon>
          <span style="margin-right: 30px">Fecha cita: <span style="color: orange">{{pedido.fechaContacto | date:'dd/MM/yyyy-HH:mm'}}</span></span>
          <span style="margin-right: 30px"> Referencia: {{pedido.referencia}} </span>
          <span style="margin-right: 30px"> Archivos adjuntos: {{pedido.archivos.length}} </span>
          <span style="margin-left: auto"
            ><button title="Adquirir" (click)="seleccionarDV(pedido)" style="background-color: white">
              <mat-icon>queue</mat-icon>
            </button></span
          >
        </h2>
        <p>
          El solicitante con perfil TECLA: {{(pedido?.usuarioTCLA != undefined ? pedido?.usuarioTCLA : 'Desconocido')}}
          {{(pedido.solicitudDVNumeroWhatsApp? '| Num. Whatsapp: ' + pedido.solicitudDVNumeroWhatsApp : '')}}
        </p>
      </ion-label>
    </ion-item>
  </div>
</ion-content>

import { Cliente } from "./Cliente";
import { Usuario } from "./Usuario";
import { SolicitudDV } from "./SolicitudDV";

export type EstadoPedido =
  | "creado"
  | "paraDiseñar"
  | "diseñando"
  | "diseñado"
  | "paraTraducir"
  | "enTraduccion"
  | "traducido"
  | "paraRevisar"
  | "enRevision"
  | "revisado"
  | "paraEvaluar"
  | "evaluando"
  | "evaluado"
  | "paraProcesar"
  | "procesando"
  | "procesado"
  | "pendientePago-prepago"
  | `maduracion-${number}`
  | "fabricacion"
  | "esperaEnMuelle"
  | "transitTime"
  | "pendientePago"
  | "finalizado"
  | "anulado"
  | "bloqueado";

export class Pedido {
  public _id: string;

  public identificadorPedido: string;

  public cliente: Cliente;
  public presupuesto: boolean;
  public presupuestoRealizacionAceptada: boolean;
  public solicitudDV: string | SolicitudDV;
  public solicitudDVconfirmada: boolean;
  public referencia: string;
  public prioridad: number;

  // Fecha DV
  public fechaContacto: Date;

  // Turno acción DV
  public turnoAccionDisenador: boolean;
  public notificarTurnoAccionDisenador: boolean;

  // ID de la reunión DV
  public enlaceReunionDV: string;

  // Telefono de contacto whatsapp
  public solicitudDVNumeroWhatsApp: number;

  // Fechas TRADUCCIÓN
  public fechaCreacion: Date;
  public fechaFinalizado: Date;
  public fechaUltimaMod: Date;

  public creado: Usuario;
  public disenado: Usuario;
  public traducido: Usuario;
  public revisado: Usuario;
  public evaluado: Usuario;
  public procesado: Usuario;

  public observaciones: string;

  public pedidoAprendiz: boolean;
  public fiabilidad: number;
  public evaluacion: {
    puntuacion: number;
    usuario: Usuario;
    observaciones: string;
    errores: string[];
  };
  public incidencias: string[];

  public BNA: string;
  public retraso: boolean;
  public enviadoFTP: boolean;
  public finalizado: boolean;
  public clasificado: boolean;
  public archivos: string[];
  public archivosNuevos: File[];

  public estado: EstadoPedido;
  public tracking: {
    estado: EstadoPedido;
    fecha: Date;
  }[];

  public pedidoTCLA: string;
  public usuarioTCLA: string;

  public totalPedidoEnPuntos: number;

  public nini: boolean;
  public presupuestoSinComision: boolean;

  public _nuevo: boolean;

  public provieneDelPresupuesto?: String;

  public identificadorPedidoDelPresupuestoDelQueProviene?: String;

  id_conversacion?: string;

  public static getIconFichero(fileName: string): string {
    const extension = fileName.split(".")[fileName.split(".").length - 1].toLowerCase();
    const path = "assets/imgs/iconosFicheros/";
    let icon;
    switch (extension) {
      case "ai":
        icon = "ai.png";
        break;
      case "avi":
        icon = "avi.png";
        break;
      case "bmp":
        icon = "bmp.png";
        break;
      case "css":
        icon = "css.png";
        break;
      case "csv":
        icon = "csv.png";
        break;
      case "dbf":
        icon = "dbf.png";
        break;
      case "doc":
        icon = "doc.png";
        break;
      case "docx":
        icon = "docx.png";
        break;
      case "dwg":
        icon = "dwg.png";
        break;
      case "html":
        icon = "html.png";
        break;
      case "jpg":
        icon = "jpg.png";
        break;
      case "js":
        icon = "js.png";
        break;
      case "json":
        icon = "json.png";
        break;
      case "pdf":
        icon = "pdf.png";
        break;
      case "png":
        icon = "png.png";
        break;
      case "ppt":
        icon = "ppt.png";
        break;
      case "psd":
        icon = "psd.png";
        break;
      case "rtf":
        icon = "rtf.png";
        break;
      case "svg":
        icon = "svg.png";
        break;
      case "txt":
        icon = "txt.png";
        break;
      case "xls":
        icon = "xls.png";
        break;
      case "xlsx":
        icon = "xlsx.png";
        break;
      case "xml":
        icon = "xml.png";
        break;
      case "zip":
        icon = "zip.png";
        break;
      default:
        icon = "otro.png";
    }

    return path + icon;
  }

  public constructor(p: any = null, nuevo: boolean = false) {
    if (p != null) {
      this._id = p._id;

      this.identificadorPedido = p.identificadorPedido;

      this.cliente = p.cliente != null ? new Cliente(p.cliente) : null;
      this.presupuesto = p.presupuesto;

      if (p.solicitudDV != null && typeof p.solicitudDV === "string") {
        this.solicitudDV = p.solicitudDV;
        this.solicitudDVconfirmada = p.solicitudDVconfirmada;
      } else if (p.solicitudDV != null) {
        this.solicitudDV = new SolicitudDV(p.solicitudDV);
      }

      if (p.solicitudDVNumeroWhatsApp) {
        this.solicitudDVNumeroWhatsApp = +p.solicitudDVNumeroWhatsApp;
      }

      this.referencia = p.referencia;
      this.prioridad = p.prioridad;

      // Turno de acción
      this.turnoAccionDisenador = typeof p.turnoAccionDisenador !== "undefined" ? p.turnoAccionDisenador : true;
      this.notificarTurnoAccionDisenador = typeof p.notificarTurnoAccionDisenador !== "undefined" ? p.notificarTurnoAccionDisenador : false;

      // Id reunión DV
      this.enlaceReunionDV = typeof (p.enlaceReunionDV !== "undefined") ? p.enlaceReunionDV : "";

      // Fecha turno DV
      this.fechaContacto = p.fechaContacto != null ? new Date(p.fechaContacto) : null;

      // Fechas TRADUCCIÓN
      this.fechaCreacion = p.fechaCreacion != null ? new Date(p.fechaCreacion) : null;
      this.fechaFinalizado = p.fechaFinalizado != null ? new Date(p.fechaFinalizado) : null;
      this.fechaUltimaMod = p.fechaUltimaMod != null ? new Date(p.fechaUltimaMod) : null;

      this.creado = p.creado != null ? new Usuario(p.creado) : null;
      this.disenado = p.disenado != null ? new Usuario(p.disenado) : null;
      this.traducido = p.traducido != null ? new Usuario(p.traducido) : null;
      this.revisado = p.revisado != null ? new Usuario(p.revisado) : null;
      this.evaluado = p.evaluado != null ? new Usuario(p.evaluado) : null;
      this.procesado = p.procesado != null ? new Usuario(p.procesado) : null;

      this.observaciones = p.observaciones;

      this.pedidoAprendiz = p.pedidoAprendiz;
      this.fiabilidad = p.fiablidad;
      this.evaluacion = p.evaluacion;
      this.incidencias = p.incidencias || [];

      this.BNA = p.BNA;
      this.retraso = p.retraso;
      this.enviadoFTP = p.enviadoFTP;
      this.finalizado = p.finalizado;
      this.clasificado = p.clasificado;
      this.archivos = p.archivos || [];
      this.archivosNuevos = [];

      this.estado = p.estado;
      this.tracking = p.tracking || [];
      this.tracking.forEach((item) => (item.fecha = item.fecha != null ? new Date(item.fecha) : null));

      this.pedidoTCLA = p.pedidoTCLA;
      this.usuarioTCLA = p.usuarioTCLA;

      this.presupuestoRealizacionAceptada = p.presupuestoRealizacionAceptada;

      this.totalPedidoEnPuntos = p.totalPedidoEnPuntos;
      this.nini = p.nini;
      this.presupuestoSinComision = p.presupuestoSinComision;

      this.provieneDelPresupuesto = p.provieneDelPresupuesto;

      this.identificadorPedidoDelPresupuestoDelQueProviene = p.identificadorPedidoDelPresupuestoDelQueProviene;

      this.id_conversacion = p.id_conversacion;
    }
    this._nuevo = nuevo;
  }

  public get color(): string {
    // Color verde
    if (this.estado === "finalizado" || this.estado === "anulado") {
      return "pedidoCompletado";
    }

    if (this.estado === "bloqueado") {
      return "pedidoFail";
    }

    const hoy = new Date().getTime();
    const inicio = this.fechaUltimaMod.getTime();

    const transcurrido = (hoy - inicio) / 1000 / 60 / 60 / 24;

    if (transcurrido >= 5 && transcurrido < 10) {
      // Color amarillo
      return "pedidoGood";
    } else if (transcurrido >= 10) {
      // Color rojo
      return "pedidoFail";
    }
    return "pedidoOk";
  }
  public get icono(): string {
    if (this.estado.indexOf("maduracion") !== -1) {
      return "hourglass_empty";
    } else {
      switch (this.estado) {
        case "paraDiseñar":
          return "new_releases";
        case "diseñando":
          return "assignment_ind";
        case "diseñado":
          return "cloud_circle";
        case "paraTraducir":
          return "new_releases";
        case "enTraduccion":
          return "assignment_ind";
        case "traducido":
          return "cloud_circle";
        case "paraRevisar":
          return "new_releases";
        case "enRevision":
          return "search";
        case "revisado":
          return "cloud_circle";
        case "paraEvaluar":
          return "new_releases";
        case "evaluando":
          return "thumbs_up_down";
        case "paraProcesar":
          return "new_releases";
        case "procesando":
          return "build";
        case "pendientePago-prepago":
          return "account_balance_wallet";
        case "fabricacion":
          return "precision_manufacturing";
        case "esperaEnMuelle":
          return "house_siding";
        case "transitTime":
          return "local_shipping";
        case "pendientePago":
          return "account_balance_wallet";
        case "finalizado":
          return "check_circle";
        case "anulado":
          return "cancel";
        case "bloqueado":
          return "report_problem";
        default:
          return "assignment";
      }
    }
  }
  public get personaMostrar(): string {
    switch (this.estado) {
      case "paraDiseñar":
        return "Nadie";
      case "diseñando":
      case "diseñado":
        return this.disenado != null ? this.disenado.nombreApellidos : "Nadie";
      case "paraTraducir":
        return "Nadie";
      case "enTraduccion":
      case "traducido":
        return this.traducido != null ? this.traducido.nombreApellidos : "Nadie";
      case "paraRevisar":
        return "Nadie";
      case "enRevision":
      case "revisado":
        return this.revisado != null ? this.revisado.nombreApellidos : "Nadie";
      case "paraEvaluar":
        return "Nadie";
      case "evaluando":
        return this.evaluado != null ? this.evaluado.nombreApellidos : "Nadie";
      case "paraProcesar":
        return "Nadie";
      case "procesando":
      case "finalizado":
        return this.procesado != null ? this.procesado.nombreApellidos : "Nadie";
      case "anulado":
        return "Nadie";
      default:
        return "Nadie";
    }
  }
  public get estadoMostrar(): string {
    if (this.estado.indexOf("maduracion") !== -1) {
      if (this.estado === "maduracion-0") {
        return "Maduración";
      } else {
        const puntoDeControl = parseInt(this.estado.split("-")[1], 10);
        return this.getPuntoControl(puntoDeControl);
      }
    } else {
      switch (this.estado) {
        case "creado":
          return "Creado";
        case "paraDiseñar":
          return "Sin Adquirir";
        case "diseñando":
          return "Adquirida";
        case "diseñado":
          return "3D realizado";
        case "paraTraducir":
          return "Para Traducir";
        case "enTraduccion":
          return "En Traducción";
        case "traducido":
          return "Traducido";
        case "paraRevisar":
          return "Para Revisar";
        case "enRevision":
          return "En Revisión";
        case "revisado":
          return "Revisado";
        case "paraEvaluar":
          return "Para Evaluar";
        case "evaluando":
          return "Evaluando";
        case "paraProcesar":
          return "Para Procesar";
        case "procesando":
          return "Procesando";
        case "procesado":
          return "Procesado";
        case "pendientePago-prepago":
          return "Pendiente Pago";
        case "fabricacion":
          return "Fabricación";
        case "esperaEnMuelle":
          return "Espera en Muelle";
        case "transitTime":
          return "Transit Time";
        case "pendientePago":
          return "Pendiente Pago";
        case "finalizado":
          return "Finalizado";
        case "anulado":
          return "Anulado";
        case "bloqueado":
          return "Bloqueado";
        default:
          return "Desconocido";
      }
    }
  }
  public get pesoNota(): number {
    if (this.evaluacion == null || this.evaluacion.puntuacion == null) {
      return 0;
    }
    if (this.evaluacion.puntuacion >= 5) {
      return 1;
    } else if (this.evaluacion.puntuacion < 5 && this.evaluacion.puntuacion >= 0) {
      return 10;
    } else {
      return 0;
    }
  }
  public get pedidoFinalizadoGestor(): boolean {
    return (
      this.estado === "pendientePago-prepago" ||
      this.estado.indexOf("maduracion") !== -1 ||
      this.estado === "fabricacion" ||
      this.estado === "esperaEnMuelle" ||
      this.estado === "transitTime" ||
      this.estado === "pendientePago" ||
      this.estado === "finalizado" ||
      this.estado === "anulado"
    );
  }
  public get alertFiabilidad(): string {
    if (this.fiabilidad == null) {
      return "alert-danger";
    } else {
      if (this.fiabilidad >= 8) {
        return "alert-success";
      } else if (this.fiabilidad >= 5 && this.fiabilidad < 8) {
        return "alert-warning";
      } else {
        return "alert-danger";
      }
    }
  }
  public get estadoToNumero(): number {
    if (this.estado.indexOf("maduracion") !== -1) {
      return 12;
    } else {
      switch (this.estado) {
        case "creado":
          return 0;
        case "paraDiseñar":
          return 1;
        case "diseñando":
          return 2;
        case "diseñado":
          return 3;
        case "paraTraducir":
          return 4;
        case "enTraduccion":
          return 5;
        case "traducido":
          return 6;
        case "paraRevisar":
          return 7;
        case "enRevision":
          return 8;
        case "revisado":
          return 9;
        case "paraEvaluar":
          return 10;
        case "evaluando":
          return 11;
        case "paraProcesar":
          return 12;
        case "procesando":
          return 13;
        case "pendientePago-prepago":
          return 14;
        case "fabricacion":
          return 15;
        case "esperaEnMuelle":
          return 16;
        case "transitTime":
          return 17;
        case "pendientePago":
          return 18;
        case "bloqueado":
          return 997;
        case "anulado":
          return 998;
        case "finalizado":
          return 999;
        default:
          return -1;
      }
    }
  }
  public get mostrarObservaciones(): boolean {
    if (this.observaciones == null) {
      return false;
    }
    return this.observaciones.trim().length > 0;
  }
  public get obervacionesLineas(): string[] {
    if (this.observaciones !== undefined) {
      return this.observaciones.split("<nota>");
    } else {
      return [];
    }
  }

  public getFechaTracking(estado: EstadoPedido): Date | null {
    const array = this.tracking.filter((e) => e.estado === estado);
    if (array.length) {
      return array[array.length - 1].fecha;
    } else {
      return null;
    }
  }

  private getPuntoControl(n: number): string {
    switch (n) {
      case 1:
        return "Lacado";
      case 2:
        return "Cristal";
      case 3:
        return "Lacado y Cristal";
      case 4:
        return "Somier";
      case 5:
        return "Lacado y Somier";
      case 6:
        return "Cristal y SOmier";
      case 7:
        return "Lacado, Cristal y Somier";
      case 8:
        return "Descanso";
      case 9:
        return "Lacado y Descanso";
      case 10:
        return "Cristal y Descanso";
      case 11:
        return "Lacado, Cristal y Descanso";
      case 12:
        return "Somier y Descanso";
      case 13:
        return "Lacado, Somier y Descanso";
      case 14:
        return "Cristal, Somier y Descanso";
      case 15:
        return "Lacado, Cristal, Somier y Descanso";
      case 16:
        return "MDF";
      case 17:
        return "Lacado y MDF";
      case 18:
        return "Cristal y MDF";
      case 19:
        return "Lacado, Cristal y MDF";
      case 20:
        return "Somier y MDF";
      case 21:
        return "Lacado, Somier y MDF";
      case 22:
        return "Cristal, Somier y MDF";
      case 23:
        return "Lacado, Cristal, Somier y MDF";
      case 24:
        return "Descanso y MDF";
      case 25:
        return "Lacado, Descanso y MDF";
      case 26:
        return "Cristal, Descanso y MDF";
      case 27:
        return "Lacado, Cristal, Descanso y MDF";
      case 28:
        return "Somier, Descanso y MDF";
      case 29:
        return "Lacado, Somier, Descanso y MDF";
      case 30:
        return "Cristal, Somier, Descanso y MDF";
      case 31:
        return "Lacado, Cristal, Somier, Descanso y MDF";
    }
  }
}

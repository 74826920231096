import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { GestorDescargasPage } from './gestor-descargas.page';
import {MatIconModule} from '@angular/material/icon';
import {ngfModule} from 'angular-file';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        MatIconModule,
        ngfModule,
        MatTooltipModule,
        MatMenuModule,
    ],
  exports: [
    GestorDescargasPage
  ],
  declarations: [GestorDescargasPage]
})
export class GestorDescargasPageModule {}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Rol, Usuario } from "../modelos/Usuario";
import { Pedido } from "../modelos/Pedido";
import { FicheroTop } from "../modelos/FicheroTop";
import { NotificacionPedido } from "../modelos/NotificacionPedido";
import { Cliente } from "../modelos/Cliente";
import { ConfigTEstimados, ConfigTEstimado, ConfigVacaciones } from "../modelos/ConfiguracionGestorPedidos";
import { ModalController, PopoverController } from "@ionic/angular";
import { DialogPage } from "../pages/dialog/dialog.page";
import { DialogConfig } from "../modelos/DialogConfig";
import { SolicitudDV } from "../modelos/SolicitudDV";
import { MatSnackBar } from "@angular/material/snack-bar";

export type PedidosMostrar = "diseñadorVirtual" | "traduccion" | "revision" | "evaluar" | "procesar" | "pendientesPago" | "todos" | "bloqueados";
export type OpcionVer = "usuario" | "todos" | "noAsignados" | "asignados";
export type OrdenPedidos = "antiguedad" | "ultimaMod" | "estado";

@Injectable({
  providedIn: "root",
})
export class AppService {
  // Para desarrollo local
  // public urlServer = "https://localhost:3008";
  // public urlServerTECLA = "https://localhost:3006";
  // public urlServerBalder = "https://localhost:3010";
  // public urlServerPedidos = "https://localhost:3011";
  // public urlServerLocal = "";

  // Para producción
  public urlServer = "https://mueblesdormacrm.com:3008";
  public urlServerTECLA = "https://catalogotecla.com";
  public urlServerBalder = "https://mueblesdormacrm.com:3010";
  public urlServerPedidos = "https://mueblesdormacrm.com:3011";
  public urlServerLocal = "http://192.168.0.150:4310";

  public pedidos: Pedido[] = [];
  public pedidosDVenCache: Pedido[] = []; // Cache de pedidos para filtrar solicitudes DV (Diseño Virtual) a los asesores - No van a ver todos los pedidos sin asignar, pero si los necesitan disponibles (en la cache) para seleccionarlos en un modal.
  public pedidosDVWhatsapp: any[] = []; // Caché de pedidos de whatsapp sin confirmar.
  public cantidadDVWhatsappSinConversacion: number = 0;

  public conversacionesTelefonicasClientes: any = []; // Caché de conversaciones telefónicas

  public pedidoSeleccionado: Pedido;
  public blur: boolean;

  public cargandoDatos: boolean;
  public textoBusqueda: string;
  public mostrar: PedidosMostrar; // todos, traducción, revision, evaluar, procesar, bloqueados.
  public opcionVer: OpcionVer; // usuario, todos, noAsignados, asignados
  public userSel: Usuario | null;
  public ordenPedidos: OrdenPedidos;
  public sentidoPedidos: boolean;

  public readonly version = "v1.4.3";
  public userLogin: Usuario;
  public userCheckedUpdate: boolean;

  // Todos los usuarios activos, independientemente del Rol.
  public usuarios: Usuario[];

  public numNoAsignados: number;
  public numNotificacionesPendientes: number;
  public numPedidosDV: number;

  public pedidosDVSinIndicarPuntosConFechaDeContactoSuperiorAHoy: any[] = [];

  public telefonoSeleccionadoEnDetallesPedido = "";

  public idTelefonoSeleccionadoEnDetallesPedido = "";

  constructor(public http: HttpClient, private snackBar: MatSnackBar, private popoverCtrl: PopoverController, private modalCtrl: ModalController) {
    this.mostrar = "traduccion";
    this.opcionVer = "usuario";

    this.userCheckedUpdate = true;
  }

  public abrirCrm(): void {
    window.open(this.urlServer);
  }

  public seleccionarPedido(_id: string): void {
    this.pedidoSeleccionado = null;
    this.cargandoDatos = true;
    this.getPedido(_id).subscribe(
      (value) => {
        this.cargandoDatos = false;
        this.pedidoSeleccionado = new Pedido(value);

        const pos = this.pedidos.findIndex((e) => e._id === value._id);
        if (pos !== -1) {
          this.pedidos[pos] = this.pedidoSeleccionado;
        }

        setTimeout(() => {
          const el = document.getElementById(_id);
          el.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest",
          });
        }, 100);

        this.checkSolicitudDV()
          .then(() => {})
          .catch((err) => {
            console.error(err);
            this.snackBar.open("Se ha producido un error al cargar la solicitud de DV", "Ok", { duration: 3500 });
          });
      },
      () => {
        this.cargandoDatos = false;
      }
    );
  }
  public cambiarPedidosMostrar(mostrar: PedidosMostrar = "todos", nTextoBusqueda: string = ""): void {
    this.pedidoSeleccionado = null;
    this.mostrar = mostrar;
    this.textoBusqueda = nTextoBusqueda;
    this.pedidos = [];

    if (this.soy("asesor") || this.soy("comercial")) {
      this.opcionVer = "usuario";
      this.userSel = this.userLogin;
    } else {
      this.opcionVer = "todos";
      this.userSel = null;
    }

    this.buscarPedidos();
  }
  public buscarPedidos(): void {
    const criteriosStr = this.textoBusqueda;

    if (criteriosStr != null && criteriosStr.trim().length) {
      const criterios = criteriosStr.split(";");
      this.opcionVer = "todos";
      this.userSel = null;

      this.getPedidos({ criterios: criterios });
    } else {
      this.getPedidos(null);
    }
  }

  public async promiseObtenerConversacionesTelefonicas() {
    return new Promise((resolve, reject) => {
      this.http.get(this.urlServer + "/conversacionestel").subscribe(
        (response) => {
          resolve(response);
        },
        (err) => {
          console.error(err);
          reject();
        }
      );
    });
  }

  public actualizarPedidosWhatsapp() {
    let res: Observable<any>;
    res = this.getPedidosDV();

    res.subscribe(
      async (pedidosRes) => {
        // Creamos una variable en la que vamos a instanciar cada elemento de la respuesta como miembro de la clase Pedido.
        // Reiniciamos las anteriores variables
        let pedidosResCache = [];
        this.pedidosDVWhatsapp = [];
        this.pedidosDVenCache = [];
        this.cantidadDVWhatsappSinConversacion = 0;

        // Actualizamos las conversaciones telefónicas
        this.conversacionesTelefonicasClientes = await this.promiseObtenerConversacionesTelefonicas();

        for (let i = 0; i < pedidosRes.length; i++) {
          //Instanciamos un pedido por cada elemento
          pedidosResCache.push(new Pedido(pedidosRes[i]));

          // Filtramos los elementos a pedidosDVWhatsapp que tienen las condiciones de ser pedido dv por whatsapp, no confirmado y estado para diseñar.
          if (pedidosRes[i].solicitudDVNumeroWhatsApp && !pedidosRes[i].solicitudDVconfirmada && pedidosRes[i].estado == "paraDiseñar") {
            // Comprobamos si ya se ha comunicado con el cliente, es decir, si se ha registrado conversación con él.
            if (
              pedidosRes[i].solicitudDVNumeroWhatsApp &&
              this.conversacionesTelefonicasClientes.some((a: any) => a.numeroTelefono == pedidosRes[i].solicitudDVNumeroWhatsApp)
            ) {
              pedidosRes[i].contactadoPorWhatsapp = true;
            } else {
              this.cantidadDVWhatsappSinConversacion++;
            }
            this.pedidosDVWhatsapp.push(pedidosRes[i]);
          }

          // Filtramos los elementos a pedidosDVEnCache que no tienen estado "diseñando" y tenga la solicitudDVconfirmada
          if (pedidosResCache[i].estado == "paraDiseñar" && pedidosResCache[i].solicitudDVconfirmada) {
            this.pedidosDVenCache.push(pedidosResCache[i]);
          }
        }

        // Ordenamos los diferentes arrays que hemos filtrado.
        //
        this.pedidosDVWhatsapp.sort((a, b) => {
          return new Date(a.fechaContacto).getTime() - new Date(b.fechaContacto).getTime();
        });
        //
        this.pedidosDVenCache.sort((a, b) => {
          return new Date(a.fechaContacto).getTime() - new Date(b.fechaContacto).getTime();
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  public getPedidos(filtro: any = null): void {
    // if (this.cargandoDatos) return;

    this.cargandoDatos = true;
    let res: Observable<any>;

    switch (this.mostrar) {
      case "todos":
        res = this.getPedidosTodos(filtro);
        break;
      case "diseñadorVirtual":
        res = this.getPedidosDV(filtro);
        break;
      case "traduccion":
        res = this.getPedidosTraduccion(filtro);
        break;
      case "evaluar":
        res = this.getPedidosEvaluar(filtro);
        break;
      case "revision":
        res = this.getPedidosRevision(filtro);
        break;
      case "procesar":
        res = this.getPedidosProcesar(filtro);
        break;
      case "bloqueados":
        res = this.getPedidosBloqueados(filtro);
        break;
      case "pendientesPago":
        res = this.getPedidosPendientesPago(filtro);
        break;
    }

    res.subscribe(
      async (pedidosRes) => {
        //Si el tipo de usuario es asesor/comercial no mostrar directamen los DV sin adquirir. Se guardan en una variable cache.
        //Los pedidos recibidos ya vienen filtrados: sólo se reciben los pendientes de asignar y los adquiridos por el asesor.

        // Creamos una variable en la que vamos a instanciar cada elemento de la respuesta como miembro de la clase Pedido.
        // Reiniciamos las anteriores variables
        let pedidosResCache = [];
        this.pedidos = [];
        this.cantidadDVWhatsappSinConversacion = 0;

        if (this.mostrar == "todos" || this.mostrar == "diseñadorVirtual") {
          this.pedidosDVWhatsapp = [];
          this.pedidosDVenCache = [];
        }

        // Actualizamos las conversaciones telefónicas
        this.conversacionesTelefonicasClientes = await this.promiseObtenerConversacionesTelefonicas();

        for (let i = 0; i < pedidosRes.length; i++) {
          //Instanciamos un pedido por cada elemento
          pedidosResCache.push(new Pedido(pedidosRes[i]));

          //Filtramos los elementos a pedidos que no tienen el estado para diseñar.
          if (pedidosResCache[i].estado !== "paraDiseñar") this.pedidos.push(pedidosResCache[i]);

          this.ordenarPor("antiguedad");

          // Filtramos los elementos a pedidosDVWhatsapp que tienen las condiciones de ser pedido dv por whatsapp, no confirmado y estado para diseñar.
          if (pedidosRes[i].solicitudDVNumeroWhatsApp && !pedidosRes[i].solicitudDVconfirmada && pedidosRes[i].estado == "paraDiseñar") {
            // Comprobamos si ya se ha comunicado con el cliente, es decir, si se ha registrado conversación con él.
            if (
              pedidosRes[i].solicitudDVNumeroWhatsApp &&
              this.conversacionesTelefonicasClientes.some((a: any) => a.numeroTelefono == pedidosRes[i].solicitudDVNumeroWhatsApp)
            ) {
              pedidosRes[i].contactadoPorWhatsapp = true;
            } else {
              this.cantidadDVWhatsappSinConversacion++;
            }
            this.pedidosDVWhatsapp.push(pedidosRes[i]);
          }

          // Filtramos los elementos a pedidosDVEnCache que no tienen estado "diseñando" y tenga la solicitudDVconfirmada
          if (pedidosResCache[i].estado == "paraDiseñar" && pedidosResCache[i].solicitudDVconfirmada) {
            this.pedidosDVenCache.push(pedidosResCache[i]);
          }
        }

        // Ordenamos los diferentes arrays que hemos filtrado.
        //
        this.pedidosDVWhatsapp.sort((a, b) => {
          return new Date(a.fechaContacto).getTime() - new Date(b.fechaContacto).getTime();
        });
        //
        this.pedidosDVenCache.sort((a, b) => {
          return new Date(a.fechaContacto).getTime() - new Date(b.fechaContacto).getTime();
        });

        this.cargandoDatos = false;
      },
      (error) => {
        console.error(error);
        this.cargandoDatos = false;
      },
      () => {
        this.ordenarPor(this.ordenPedidos);
        this._moverScrollPedido();
      }
    );

    // res.subscribe(
    //   (pedidosRes) => {
    //     //Si el tipo de usuario es asesor/comercial no mostrar directamen los DV sin adquirir. Se guardan en una variable cache.
    //     //Los pedidos recibidos ya vienen filtrados: sólo se reciben los pendientes de asignar y los adquiridos por el asesor.
    //     this.pedidosDVenCache = pedidosRes.map((p) => new Pedido(p));
    //     this.pedidos = this.pedidosDVenCache.filter(
    //       (p) => p.estado != "paraDiseñar"
    //     );

    //     this.pedidosDVWhatsapp = this.pedidosDVenCache
    //       .filter(
    //         (p) =>
    //           p.solicitudDVNumeroWhatsApp &&
    //           !p.solicitudDVconfirmada &&
    //           p.estado == "paraDiseñar"
    //       )
    //       .sort((a, b) => {
    //         return (
    //           new Date(a.fechaContacto).getTime() -
    //           new Date(b.fechaContacto).getTime()
    //         );
    //       });

    //     this.pedidosDVenCache = this.pedidosDVenCache
    //       .filter((p) => p.estado != "diseñando" && p.solicitudDVconfirmada)
    //       .sort((a, b) => {
    //         return (
    //           new Date(a.fechaContacto).getTime() -
    //           new Date(b.fechaContacto).getTime()
    //         );
    //       });

    //     this.cargandoDatos = false;
    //   },
    //   (error) => {
    //     console.error(error);
    //     this.cargandoDatos = false;
    //   },
    //   () => {
    //     this.ordenarPor(this.ordenPedidos);
    //     this._moverScrollPedido();
    //   }
    // );
  }
  public ordenarPor(orden: OrdenPedidos): void {
    this.ordenPedidos = orden;

    //Lógica de orden para resultados filtrados como diseñadorVirtual
    if (this.mostrar === "diseñadorVirtual") {
      switch (this.ordenPedidos) {
        case "antiguedad":
          this.pedidos.sort((a, b) => {
            //Si ambos pedidos son DV...
            if (a.solicitudDV != null && b.solicitudDV != null) {
              //¿El primer pedido está finalizado? Colocar detrás.
              if (a.estado === "finalizado") {
                return +1;
                //El segundo pedido está finalizado? colocar primero delante
              } else if (b.estado === "finalizado") {
                return -1;
                //En caso de que ambos sean DV y ninguno esté finalizado ordena por fecha de cita
              } else {
                return new Date(a.fechaContacto).getTime() - new Date(b.fechaContacto).getTime();
              }
              //Sólo el primer pedido es DV, colocar primero delante
            } else if (a.solicitudDV != null) {
              return -1;
              //Sólo el segunfo pedido es DV, colocar primero detrás
            } else if (b.solicitudDV != null) {
              return +1;
              // En caso de que ninguno de los pedidos sean DV ordena por fecha de creación
            } else {
              return new Date(a.fechaCreacion).getTime() - new Date(b.fechaCreacion).getTime();
            }
          });
          break;
        case "ultimaMod":
          this.pedidos.sort((a, b) => new Date(a.fechaUltimaMod).getTime() - new Date(b.fechaUltimaMod).getTime());
          break;
        case "estado":
          this.pedidos.sort((a, b) => {
            const estadoA = a.estadoToNumero;
            const estadoB = b.estadoToNumero;

            if (estadoA > estadoB) {
              return 1;
            } else if (estadoA < estadoB) {
              return -1;
            } else {
              return 0;
            }
          });
          break;
      }
      //Lógica de orden para resto de vistas no filtradas como diseñadorVirtual
    } else {
      switch (this.ordenPedidos) {
        case "antiguedad":
          this.pedidos.sort((a, b) => new Date(a.fechaCreacion).getTime() - new Date(b.fechaCreacion).getTime());
          break;
        case "ultimaMod":
          this.pedidos.sort((a, b) => new Date(a.fechaUltimaMod).getTime() - new Date(b.fechaUltimaMod).getTime());
          break;
        case "estado":
          this.pedidos.sort((a, b) => {
            const estadoA = a.estadoToNumero;
            const estadoB = b.estadoToNumero;

            if (estadoA > estadoB) {
              return 1;
            } else if (estadoA < estadoB) {
              return -1;
            } else {
              return 0;
            }
          });
          break;
      }
    }

    if (!this.sentidoPedidos) {
      this.pedidos.reverse();
    }
  }
  private _moverScrollPedido(): void {
    if (this.pedidoSeleccionado != null) {
      // Mover el scroll de la vista a la posición en la que estaba antes.
      setTimeout(() => {
        const el = document.getElementById(this.pedidoSeleccionado._id);
        el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }, 100);
    }
  }
  private checkSolicitudDV(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.pedidoSeleccionado.solicitudDV != null && typeof this.pedidoSeleccionado.solicitudDV === "string") {
        this.getSolicitudDV(this.pedidoSeleccionado.solicitudDV).subscribe(
          (response) => {
            if (response != null && response[0] != null) {
              this.pedidoSeleccionado.solicitudDV = new SolicitudDV(response[0]);
            }
            resolve();
          },
          (error) => reject(error)
        );
      } else {
        resolve();
      }
    });
  }

  // ACCIONES
  public getAccionesPedido(idPedido: string): Observable<any> {
    return this.http.get(this.urlServer + "/acciones/getAccionesPedido/" + idPedido);
  }

  // Actualizaciones \\
  public getUserCheckedActualizacion(version: string, usuario: string): Observable<any> {
    return this.http.post(this.urlServer + "/actualizacionesGP/getUserCheckedActualizacion", { version: version, usuario: usuario });
  }
  public setUserCheckedActualizacion(version: string, usuario: string): Observable<any> {
    return this.http.post(this.urlServer + "/actualizacionesGP/setUserCheckedActualizacion", { version: version, usuario: usuario });
  }

  // CONFIGURACIÓN DEL GESTOR DE PEDIDOS \\
  public getConfig(): Observable<any> {
    return this.http.get(this.urlServer + "/configuracionGestorPedidos/getConfig");
  }
  public setConfigTraduccion(configTraduccion: ConfigTEstimado): Observable<any> {
    return this.http.post(this.urlServer + "/configuracionGestorPedidos/setConfigTraduccion", { configTraduccion: configTraduccion });
  }
  public setConfigRevision(configRevision: ConfigTEstimado): Observable<any> {
    return this.http.post(this.urlServer + "/configuracionGestorPedidos/setConfigRevision", { configRevision: configRevision });
  }
  public setConfigElemIncorporables(configElemIncorporables: ConfigTEstimados): Observable<any> {
    return this.http.post(this.urlServer + "/configuracionGestorPedidos/setConfigElemIncorporables", {
      configElemIncorporables: configElemIncorporables,
    });
  }
  public setConfigMaduracion(configMaduracion: ConfigTEstimado): Observable<any> {
    return this.http.post(this.urlServer + "/configuracionGestorPedidos/setConfigMaduracion", { configMaduracion: configMaduracion });
  }
  public setConfigFabricacion(configFabricacion: ConfigTEstimado): Observable<any> {
    return this.http.post(this.urlServer + "/configuracionGestorPedidos/setConfigFabricacion", { configFabricacion: configFabricacion });
  }
  public setConfigEsperaEnMuelle(configEsperaEnMuelle: ConfigTEstimado): Observable<any> {
    return this.http.post(this.urlServer + "/configuracionGestorPedidos/setConfigEsperaEnMuelle", { configEsperaEnMuelle: configEsperaEnMuelle });
  }
  public setConfigVacaciones(configVacaciones: ConfigVacaciones[]): Observable<any> {
    return this.http.post(this.urlServer + "/configuracionGestorPedidos/setConfigVacaciones", { configVacaciones: configVacaciones });
  }

  // NOTIFICACIONES DE USUARIO \\
  public getNotificacionesUsuario(idUser: string): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/getNotificacionesUsuario", { idUser: idUser });
  }
  public getNotificacionesUsuarioPendientes(idUser: string): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/getNotificacionesUsuarioPendientes", { idUser: idUser });
  }
  public getNumNotificacionesUsuarioPendientes(idUser: string): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/getNumNotificacionesUsuarioPendientes", { idUser: idUser });
  }
  public getIncicidencias(): Observable<any> {
    return this.http.get(this.urlServer + "/pedidos/getIncidencias");
  }
  public getNumIncidenciasUsuarioPendientes(idUser: string): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/getNumIncidenciasUsuarioPendientes", { idUser: idUser });
  }
  public getNumIncidenciasUsuario(idUser: string): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/getNumIncidenciasUsuario", { idUser: idUser });
  }
  public getNumIncidenciasUsuarioFecha(idUser: string, inicio: Date, fin: Date): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/getNumIncidenciasUsuarioFecha", { idUser: idUser, inicio: inicio, fin: fin });
  }
  public setVistoNotificacion(notificacion: any): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/setVistoNotificacion", {
      notificacion: notificacion,
    });
  }
  public setCartaDescargada(notificacion: NotificacionPedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/setCartaDescargada", {
      notificacion: notificacion,
    });
  }

  // Get Pedidos \\
  public getPedidosDV(filtro: any = null): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/getPedidosDV", {
      userLogin: this.userLogin,
      pedidosVer: this.opcionVer,
      user: this.userSel,
      filtro: filtro,
    });
  }
  public getPedidosTraduccion(filtro: any = null): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/getPedidosTraduccionN", {
      userLogin: this.userLogin,
      pedidosVer: this.opcionVer,
      user: this.userSel,
      filtro: filtro,
    });
  }
  public getPedidosRevision(filtro: any = null): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/getPedidosRevisionN", {
      userLogin: this.userLogin,
      pedidosVer: this.opcionVer,
      user: this.userSel,
      filtro: filtro,
    });
  }
  public getPedidosEvaluar(filtro: any = null): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/getPedidosEvaluar", {
      pedidosVer: this.opcionVer,
      user: this.userSel,
      filtro: filtro,
    });
  }
  public getPedidosProcesar(filtro: any = null): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/getPedidosProcesarN", {
      pedidosVer: this.opcionVer,
      user: this.userSel,
      filtro: filtro,
    });
  }
  public getPedidosTodos(filtro: any = null): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/getPedidosTodos", {
      userLogin: this.userLogin,
      filtro: filtro,
    });
  }
  public getPedidosBloqueados(filtro: any = null): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/getPedidosBloqueados", {
      userLogin: this.userLogin,
      filtro: filtro,
    });
  }
  public getPedidosPendientesPago(filtro: any = null): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/getPedidosPendientesPago", { userLogin: this.userLogin, filtro: filtro });
  }

  public getPedidosFecha(fecha: Date = new Date()): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/getPedidosFecha", {
      fecha: fecha,
    });
  }
  public getPedidosGroupByDate(groupTipo: string): Observable<any> {
    return this.http.get(this.urlServer + "/pedidos/pedidosGroupByDate/" + groupTipo);
  }
  public getEvaluaciones(idUser: string): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/getEvaluaciones", {
      idUser: idUser,
    });
  }
  public getTendenciaUsuario(idUser: string): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/getTendenciaUsuario", {
      idUser: idUser,
    });
  }

  public getNumPedidosTraduccion(): Observable<any> {
    return this.http.get(this.urlServer + "/pedidos/getNumPedidosTraduccion");
  }
  public getNumPedidosRevision(): Observable<any> {
    return this.http.get(this.urlServer + "/pedidos/getNumPedidosRevision");
  }

  public getRendimiento(idUser: string): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/getRendimiento", {
      idUser: idUser,
    });
  }

  public getClienteFinalPedido(idUser: string): Observable<any> {
    return this.http.get(this.urlServerTECLA + "/dameUsuarioDeLaSolicitud/" + idUser);
  }

  public getSolicitudesDvDelClienteFinal(idUser: string): Observable<any> {
    return this.http.get(this.urlServerTECLA + "/dameSolicitudesDvDelUsuarioDeLaSolicitud/" + idUser);
  }

  public getPedidosDvDelClienteFinal(arraySolicitudesDv: [any]): Observable<any> {
    return this.http.post(this.urlServer + "/getPedidosDeArrayDeIdsDePedidos", arraySolicitudesDv);
  }

  // Funciones de Pedidos y Presupuestos \\
  public crearPedido(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/nuevo", pedido);
  }
  public updatePedido(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/update", {
      pedido: pedido,
    });
  }
  public crearPedidoRevisar(pedido: Pedido, fichero: FicheroTop): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/nuevoRevisar", {
      pedido: pedido,
      fichero: fichero,
    });
  }
  public asignarCliente(pedido: Pedido, cliente: Cliente): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/asignarClienteN", {
      pedido: pedido,
      cliente: cliente,
    });
  }
  public asignarReferencia(pedido: Pedido, referencia: string): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/asignarReferencia", {
      pedido: pedido,
      referencia: referencia,
    });
  }
  public asignarBNA(pedido: Pedido, bna: string): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/asignarBNA", {
      pedido: pedido,
      bna: bna,
    });
  }
  public asignarEvaluacion(pedido: Pedido, evaluacion: any): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/asignarEvaluacion", {
      pedido: pedido,
      evaluacion: evaluacion,
    });
  }
  public getInfoEntregaGP(pedido: string): Observable<any> {
    return this.http.post(this.urlServerBalder + "/descargas/getInfoEntregaGP", { pedido: pedido });
  }
  public getInfoCargaGP(cargas: string[]): Observable<any> {
    return this.http.post(this.urlServerBalder + "/descargas/getInfoCargaGP", {
      idCargas: cargas,
    });
  }
  public getInfoCargaClienteGP(cargas: string[], cliente: string): Observable<any> {
    return this.http.post(this.urlServerBalder + "/descargas/getInfoCargaClienteGP", { idCargas: cargas, cliente: cliente });
  }
  public getCargasGP(): Observable<any> {
    return this.http.get(this.urlServerBalder + "/descargas/getCargasGP");
  }
  public descargarPackageList(pedido: string): Observable<any> {
    return this.http.post(this.urlServerBalder + "/descargas/descargarPackageList", { pedido: pedido });
  }
  public getBultos(bulto: string): Observable<any> {
    return this.http.get(this.urlServerBalder + "/bultos/getBultos/" + bulto);
  }
  public asignarIncidencia(pedido: Pedido, defectos: string, motivo: string, observaciones: string = ""): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/asignarIncidencia", {
      pedido: pedido,
      defectos: defectos,
      motivo: motivo,
      observaciones: observaciones,
    });
  }
  public updateObservaciones(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/updateObservaciones", {
      pedido: pedido,
    });
  }
  public updateSolicitudDV(solicitud: SolicitudDV): Observable<any> {
    return this.http.post(this.urlServerTECLA + "/updateSolicitudDV/" + solicitud._id, solicitud, { responseType: "text" });
  }
  public calificarSolicitudDVnoAsistida(solicitud: SolicitudDV): Observable<any> {
    return this.http.get(this.urlServerTECLA + "/updateSolicitudDVaNoAsistida/" + solicitud._id, { responseType: "text" });
  }
  public updateObservacionesDV(solicitud: SolicitudDV): Observable<any> {
    return this.http.post(this.urlServerTECLA + "/updateSolicitudDV/" + solicitud._id, solicitud, { responseType: "text" });
  }
  public actualizarPedidoConEnlaceReunionDV_TECLA(solicitud: SolicitudDV): Observable<any> {
    return this.http.post(this.urlServerTECLA + "/updateSolicitudDV/" + solicitud._id, solicitud, { responseType: "text" });
  }
  public actualizarPedidoConEnlaceReunionDV_CRM(pedido: Pedido, emailCliente: String, asesor: Usuario): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/updatePedidoConEnlaceReunion", { pedido: pedido, emailCliente: emailCliente, asesor: asesor });
  }
  public cambiarCitaTECLA_DV(solicitud: SolicitudDV): Observable<any> {
    return this.http.post(this.urlServerTECLA + "/updateSolicitudDV/" + solicitud._id, solicitud, { responseType: "text" });
  }
  public cambiarCitaCRM_DV(pedido: Pedido, emailCliente: String): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/cambiarCitaDV", {
      pedido: pedido,
      emailCliente: emailCliente,
    });
  }
  public cambiarTurnoTeclaDV(solicitud: SolicitudDV): Observable<any> {
    return this.http.post(this.urlServerTECLA + "/updateSolicitudDV/" + solicitud._id, solicitud, { responseType: "text" });
  }
  public cambiarTurnoCRM_DV(pedido: any): Observable<any> {
    let pedidoActualizar = { ...pedido };
    pedidoActualizar.solicitudDV = pedido.solicitudDV._id;
    return this.http.post(this.urlServer + "/pedidos/updateTurnoAccion", { pedido: pedidoActualizar }, { responseType: "text" });
  }

  public mandarCorreoDistribuidor(pedido: any): Observable<any> {
    let pedidoActualizar = { ...pedido };
    pedidoActualizar.solicitudDV = pedido.solicitudDV._id;
    return this.http.post(this.urlServer + "/pedidos/mandarCorreoDistribuidorDV", { pedido: pedidoActualizar }, { responseType: "text" });
  }

  public unirPedido(pedidoOrigen: Pedido, pedidoDestino: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/unirPedido", {
      pedidoOrigen: pedidoOrigen,
      pedidoDestino: pedidoDestino,
    });
  }
  public copiarArchivo(pedidoDestino: Pedido, archivo: string): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/copiarArchivo", {
      destino: pedidoDestino,
      archivo: archivo,
    });
  }
  public moverArchivo(pedidoOrigen: Pedido, pedidoDestino: Pedido, archivo: string): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/moverArchivo", {
      origen: pedidoOrigen,
      destino: pedidoDestino,
      archivo: archivo,
    });
  }

  public actualizarPedido(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/actualizar", {
      pedido: pedido,
    });
  }

  public quitarFicheroPedido(pedido: Pedido, fichero: string): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/quitarFicheroPedido", {
      pedido: pedido,
      fichero: fichero,
    });
  }

  public adquirirPedidoDV(): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/asignarPedidoDV", {
      user: this.userLogin,
      pedido: this.pedidoSeleccionado,
    });
  }

  public conversacionAdquirida(id_conversacion, id_pedidoDvSeleccionado) {
    return this.http.put(this.urlServer + "/conversaciontel/conversacionAdquirida", { id_conversacion, id_pedidoDvSeleccionado });
  }

  public adquirirPedidoTraduccion(): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/asignarPedidoTraduccion", {
      user: this.userLogin,
    });
  }
  public adquirirPedidoTraducir(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/asignarPedidoTraducir", {
      pedido: pedido,
      user: this.userLogin,
    });
  }

  public enviadoFTPDV(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/enviadoFTPDV", {
      pedido: pedido,
      user: this.userLogin,
    });
  }

  public enviadoFTP(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/enviadoFTP", {
      pedido: pedido,
      user: this.userLogin,
    });
  }

  public adquirirPedidoRevision(): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/asignarPedidoRevision", {
      user: this.userLogin,
    });
  }
  public adquirirPedidoRevisar(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/asignarPedidoRevisar", {
      pedido: pedido,
      user: this.userLogin,
    });
  }
  public pedidoRevisado(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/pedidoRevisado", {
      pedido: pedido,
      user: this.userLogin,
    });
  }
  public pedidoRevisadoNoFTP(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/pedidoRevisadoNoFTP", {
      pedido: pedido,
      user: this.userLogin,
    });
  }

  public pedidoEvaluado(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/pedidoEvaluado", {
      pedido: pedido,
    });
  }
  public adquirirPedidoEvaluar(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/asignarPedidoEvaluar", {
      pedido: pedido,
      user: this.userLogin,
    });
  }

  public adquirirPedidoProcesar(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/asignarPedidoProcesar", {
      pedido: pedido,
      user: this.userLogin,
    });
  }
  public procesadoFinalizdo(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/procesadoFinalizadoN", {
      pedido: pedido,
      userLogin: this.userLogin,
    });
  }
  public pedidoSaltarPrepago(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/pedidoSaltarPrepago", {
      pedido: pedido,
    });
  }
  public pedidoPagado_prepago(pedido: Pedido, fechaPago: Date): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/pedidoPagado-prepago", {
      pedido: pedido,
      fechaPago: fechaPago,
      user: this.userLogin,
    });
  }

  public finalizarPresupuesto(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/finalizarPresupuesto", {
      pedido: pedido,
    });
  }

  public asignarTotalPuntosPedidoDV(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/asignarTotalPuntosPedidoDV", {
      pedido: pedido,
    });
  }

  public noComisionarPresupuesto(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/noComisionarPresupuesto", {
      pedido: pedido,
    });
  }

  public reasignarPedido(pedido: Pedido, usuarioAnt: Usuario, usuarioNuevo: Usuario): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/reasignarN", {
      pedido: pedido,
      usuarioAnt: usuarioAnt,
      usuarioNuevo: usuarioNuevo,
    });
  }
  public reasignarPedidoRevision(pedido: Pedido, usuarioAnt: Usuario, usuarioNuevo: Usuario): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/reasignarRevisionN", {
      pedido: pedido,
      usuarioAnt: usuarioAnt,
      usuarioNuevo: usuarioNuevo,
    });
  }
  public eliminarPedido(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/eliminarPedido", {
      pedido: pedido,
    });
  }
  public anularPedido(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/anularPedido", {
      pedido: pedido,
      user: this.userLogin,
    });
  }
  public cancelarAnulacion(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/cancelarAnulacion", {
      pedido: pedido,
    });
  }
  public autorizarPedido(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/autorizarPedido", {
      pedido: pedido,
      user: this.userLogin,
    });
  }
  public confirmarSolicitudDisenoVirtualPorWhatsapp(pedido: any): Observable<any> {
    return this.http.put(this.urlServer + "/confirmarSolicitudDisenoVirtualWhatsapp", pedido);
  }

  public creadoEvaluar(pedido: Pedido, autoAsignar: boolean = true): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/creadoEvaluar", {
      pedido: pedido,
      user: this.userLogin,
      autoAsignar: autoAsignar,
    });
  }
  public creadoProcesar(pedido: Pedido, autoAsignar: boolean = true): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/creadoProcesar", {
      pedido: pedido,
      user: this.userLogin,
      autoAsignar: autoAsignar,
    });
  }
  public traduccionAprendizRevisar(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/traduccionAprendizRevisar", { pedido: pedido, user: this.userLogin });
  }
  public traduccionEvaluar(pedido: Pedido, autoAsignar: boolean = true): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/traduccionEvaluar", {
      pedido: pedido,
      user: this.userLogin,
      autoAsignar: autoAsignar,
    });
  }
  public traduccionProcesar(pedido: Pedido, autoAsignar: boolean = true): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/traduccionProcesar", {
      pedido: pedido,
      user: this.userLogin,
      autoAsignar: autoAsignar,
    });
  }
  public revisionEvaluar(pedido: Pedido, autoAsignar: boolean = true): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/revisionEvaluar", {
      pedido: pedido,
      user: this.userLogin,
      autoAsignar: autoAsignar,
    });
  }
  public revisionProcesar(pedido: Pedido, autoAsignar: boolean = true): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/revisionProcesar", {
      pedido: pedido,
      user: this.userLogin,
      autoAsignar: autoAsignar,
    });
  }
  public devolverDisenador(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/devolverDisenador", {
      pedido: pedido,
    });
  }
  public devolverTraductor(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/devolverTraductor", {
      pedido: pedido,
    });
  }
  public devolverRevisor(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/devolverRevisor", {
      pedido: pedido,
    });
  }
  public devolverEvaluar(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/devolverEvaluar", {
      pedido: pedido,
    });
  }

  public quitarDisenador(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/quitarDisenador", {
      pedido: pedido,
    });
  }
  public quitarTraductor(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/quitarTraductor", {
      pedido: pedido,
    });
  }
  public quitarRevisor(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/quitarRevisor", {
      pedido: pedido,
    });
  }
  public quitarEvaluar(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/quitarEvaluar", {
      pedido: pedido,
    });
  }
  public quitarProcesar(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/quitarProcesar", {
      pedido: pedido,
    });
  }

  public getPedido(id: string): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/getOne", { id: id });
  }
  public getSolicitudDV(id: string): Observable<any> {
    return this.http.get(this.urlServerTECLA + "/dameSolicitudDV/" + id);
  }

  public getUsuariosGestorPedidos(): Observable<any> {
    return this.http.get(this.urlServer + "/pedidos/getUsuariosGestorPedidos");
  }

  public soy(rol: Rol): boolean {
    return this.userLogin != null && this.userLogin.rol === rol;
  }

  public getUserByToken(): Observable<any> {
    return this.http.get(this.urlServer + "/pedidos/getUserByToken");
  }

  public logear(nombreUsuario: string, password: string, recuerdame: boolean): Observable<any> {
    return this.http.post(this.urlServer + "/users/loginCRM", {
      nombreUsuario: nombreUsuario,
      password: password,
      recuerdame: recuerdame,
    });
  }

  public busquedaClientes(dato: string): Observable<any> {
    return this.http.get(this.urlServer + "/ipdorma/getCliMin/" + dato);
  }

  public subirArchivos(files, nombres): Observable<any> {
    const formData: FormData = new FormData();

    let i = 0;
    for (const file of files) {
      formData.append("file", file, nombres[i]);
      i++;
    }

    return this.http.post(this.urlServer + "/pedido/subirArchivoNuevo", formData, { reportProgress: true });
  }

  public obtenerPresupuestosParaVincularAPedidos(idCliente): Observable<any> {
    return this.http.get(this.urlServer + "/pedidos/obtenerPresupuestosParaVincularAPedidos/" + idCliente);
  }

  public updateFuncionAsesor(usuario: Usuario): Observable<any> {
    return this.http.post(this.urlServer + "/pedidos/updateFuncionAsesor", {
      usuario: usuario,
    });
  }

  public getFichero(fichero: string): Observable<any> {
    return this.http.post(this.urlServerPedidos + "/ficheroTop/getFichero", {
      fichero: fichero,
    });
  }

  public getFicherosDate(fecha: Date): Observable<any> {
    return this.http.post(this.urlServerPedidos + "/ficheroTop/getFicherosDate", { fecha: fecha });
  }

  public getNumPedidosDV(): Observable<any> {
    return this.http.get(this.urlServer + "/pedidos/getNumPedidosDV");
  }

  public getNumNoAsignados(): Observable<any> {
    return this.http.get(this.urlServerPedidos + "/ficheroTop/getNumNoAsignados");
  }

  public getNoAsignados(): Observable<any> {
    return this.http.get(this.urlServerPedidos + "/ficheroTop/getNoAsignados");
  }

  public getIgnorados(): Observable<any> {
    return this.http.get(this.urlServerPedidos + "/ficheroTop/getIgnorados");
  }

  public getFicherosGroupByDate(groupTipo: string): Observable<any> {
    return this.http.get(this.urlServerPedidos + "/ficheroTop/getFicherosGroupByDate/" + groupTipo);
  }

  public buscarFicheros(criterio: string): Observable<any> {
    return this.http.post(this.urlServerPedidos + "/ficherosTop/buscarFicheros", { criterio: criterio });
  }

  public ignorarFichero(fichero: FicheroTop): Observable<any> {
    return this.http.post(this.urlServerPedidos + "/ficheroTop/setIgnorado", {
      fichero: fichero,
    });
  }

  public generarPedidosN(): Observable<any> {
    return this.http.get(this.urlServerPedidos + "/ficheroTop/generarPedidosN");
  }

  public generarPedido(ficheroTop: FicheroTop): Observable<any> {
    return this.http.post(this.urlServerPedidos + "/ficheroTop/generarPedido", {
      fichero: ficheroTop,
    });
  }

  public asignarFicheroPedido(pedido: Pedido, fichero: FicheroTop): Observable<any> {
    return this.http.post(this.urlServerPedidos + "/ficheroTop/asignarFicheroPedido", { pedido: pedido, fichero: fichero });
  }

  public subirFicherosFTPServidor(): Observable<any> {
    return this.http.get(this.urlServerLocal + "/subirFicheros");
  }

  public regenerarZip(pedido: Pedido): Observable<any> {
    return this.http.post(this.urlServerPedidos + "/pedidos/regenerarZip", {
      pedido: pedido,
    });
  }

  public getTiemposEstimados(idPedido: string): Observable<any> {
    return this.http.get(this.urlServer + "/pedidos/getTiemposEstimados/" + idPedido);
  }

  public dialog(config: DialogConfig): Promise<HTMLIonModalElement> {
    return this.modalCtrl.create({
      component: DialogPage,
      cssClass: "dialog-modal",
      componentProps: {
        data: config,
      },
    });
  }

  public generarCadena(n: number): string {
    return Math.random()
      .toString(36)
      .substr(13 - n);
  }
  public getFechaString(): string {
    const hoy = new Date();
    const year = hoy.getFullYear();
    let month = (hoy.getMonth() + 1).toString();
    let date = hoy.getDate().toString();

    while (month.length < 2) {
      month = "0" + month;
    }
    while (date.length < 2) {
      date = "0" + date;
    }

    return year + "-" + month + "-" + date;
  }
  public ordenar(array: Usuario[]) {
    array.sort(function (usuarioA: Usuario, usuarioB: Usuario) {
      if (usuarioA.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "") < usuarioB.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
        return -1;
      } else if (
        usuarioA.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "") > usuarioB.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      ) {
        return 1;
      }
      if (usuarioA.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "") === usuarioB.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
        if (
          usuarioA.apellidos.normalize("NFD").replace(/[\u0300-\u036f]/g, "") < usuarioB.apellidos.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        ) {
          return -1;
        } else if (
          usuarioA.apellidos.normalize("NFD").replace(/[\u0300-\u036f]/g, "") > usuarioB.apellidos.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        ) {
          return 1;
        }
      }
      return 0;
    });
  }

  public mostrarUsuarioAsignar(usuario: Usuario, cliente: Cliente): boolean {
    if (usuario.rol !== "asesor" && usuario.rol !== "comercial") {
      return false;
    } else if (usuario.rol === "asesor") {
      return this.mostrarAsesor(usuario, cliente);
    } else if (usuario.rol === "comercial") {
      if (cliente == null || usuario.codigoRepresentante == null || !usuario.codigoRepresentante.length) {
        return false;
      }
      return usuario.codigoRepresentante.some((c) => c === cliente.representante1 || c === cliente.representante2 || c === cliente.representante3);
    }
  }
  private mostrarAsesor(usuario: Usuario, cliente: Cliente): boolean {
    // Si no es JORMA, todos están disponibles
    if (cliente == null || cliente.codigo !== "3594") {
      return true;
    } else {
      return usuario._id !== "5b151af1ebc036b0210000fa"; // Si es JORMA, no mostrar a Pablo de Málaga
    }
  }

  public obtenerConversacionesTelefonicas() {
    return this.http.get(this.urlServer + "/conversacionestel");
  }

  public agregarConversacionTelefonica(conversacion: any) {
    return this.http.post(this.urlServer + "/conversacionestel", conversacion);
  }

  public actualizarConversacionTelefonica(conversacion: any) {
    return this.http.put(this.urlServer + "/conversaciontel", conversacion);
  }

  public comprobarSiTelefonoDelClienteTieneConversaciones(telefonoClienteDv) {
    return this.http.get(this.urlServer + "/conversacionestel/" + telefonoClienteDv);
  }
}

export class Cliente {
    public _id: string;
    public codigo: string;
    public cif: string;
    public razonSocial: string;
    public nombreComercial: string;
    public provincia: string;
    public poblacion: string;
    public codigoPostal: string;

    public representante1: string;
    public representante2: string;
    public representante3: string;
    public efectos: {
      diasEntreVencimientos: number,
      diasPrimerVencimiento: number,
      efecto: string,
      estado: string,
      factura: string,
      fecha: Date,
      formaPago: string,
      moneda: string,
      pendiente: number,
      total: number,
      vencimiento: Date
    } [];

    public formaPago: string;
    public transitTime: number;

    public constructor(c: any = null) {
      if (c != null) {
        this._id = c._id;
        this.codigo = c.codigo;
        this.cif = c.cif;
        this.razonSocial = c.razonSocial;
        this.nombreComercial = c.nombreComercial;
        this.provincia = c.provincia;
        this.poblacion = c.poblacion;
        this.codigoPostal = c.codigoPostal;

        this.representante1 = c.representante1;
        this.representante2 = c.representante2;
        this.representante3 = c.representante3;
        this.efectos = c.efectos || [];
        this.formaPago = c.formaPago;
        this.transitTime = c.transitTime;
      }
    }

    public get impagos(): boolean {
      return this.efectos.filter(e => (e.estado === '256' || e.estado === '512') && e.pendiente > 0).length > 0;
    }
}

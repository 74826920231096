import { Component, OnInit } from '@angular/core';
import {AppService} from '../../servicios/app.service';
import {Download, DownloadService} from '../../servicios/DownloadService';

@Component({
  selector: 'app-gestor-descargas',
  templateUrl: './gestor-descargas.page.html',
  styleUrls: ['./gestor-descargas.page.scss'],
})
export class GestorDescargasPage implements OnInit {

  public downloadOver: string | null;

  constructor(public appService: AppService,
              public ds: DownloadService) {
    this.downloadOver = null;
  }

  ngOnInit() {
  }

  public abrirDescargas(): void {
    this.ds.mostrarGestorDescargas = true;
  }
  public cerrarDescargas(): void {
    this.ds.mostrarGestorDescargas = false;
  }

  public setDownloadOver(value: string | null): void {
    this.downloadOver = value;
  }

  public mostrarProgressBar(download: Download): boolean {
    return download.total > 0;
  }
  public getValueProgressBar(download: Download): number {
    return download.loaded / download.total;
  }
  public getColorProgressBar(download: Download): string {
    if (download.estado === 'TERMINADA') {
      if (download.hasObject) {
        return 'success';
      } else {
        return 'medium';
      }
    }
    if (download.estado === 'INTERRUMPIDA') {
      return 'danger';
    }
    return 'primary';
  }

  public getIconoDownload(download: Download): string {
    if (download.estado === 'PENDIENTE' || download.estado === 'DESCARGANDO') {
      return 'document-text';
    }
    if (download.estado === 'TERMINADA') {
      return 'checkmark-circle';
    }
    if (download.estado === 'INTERRUMPIDA') {
      return 'close-circle';
    }
  }
  public getColorIconoDownload(download: Download): string {
    if (this.downloadOver == null || this.downloadOver !== download.id) {
      return 'primary';
    } else {
      return 'dark';
    }
  }

  public detenerDescarga(download: Download): void {
    download.stopDownload();
  }
  public eliminarDescarga(download: Download): void {
    download.removeDownload();
  }
  public reiniciarDescarga(download: Download): void {
    download.startDownload();
  }
}

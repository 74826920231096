import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AppService } from "../../../servicios/app.service";
import { Pedido } from "../../../modelos/Pedido";
import { Observable } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-selector-dv",
  templateUrl: "./selector-dv.page.html",
  styleUrls: ["./selector-dv.page.scss"],
})
export class SelectorDvPage implements OnInit {
  public mostrar: string = "todos";
  public pedidosDV: any;

  pedidoDvSeleccionado: any;
  selectorDeConversacionesActivado: boolean = false;
  conversacionesTelefonicasDelCliente: any[] = [];
  id_conversacion: string = null;
  id_pedidoDvSeleccionado: string = null;

  constructor(private modalController: ModalController, public appService: AppService, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.appService.cambiarPedidosMostrar("diseñadorVirtual");
  }

  seleccionarDV(pedido) {
    this.id_conversacion = "";
    this.id_pedidoDvSeleccionado = "";
    this.pedidoDvSeleccionado = pedido;

    if (pedido.solicitudDVNumeroWhatsApp) {
      this.appService.comprobarSiTelefonoDelClienteTieneConversaciones(pedido.solicitudDVNumeroWhatsApp).subscribe((conversaciones: any[]) => {
        if (conversaciones.length > 0) {
          this.conversacionesTelefonicasDelCliente = conversaciones.filter((conversacion) => {
            return !conversacion.conversacionAdquirida;
          });
          this.selectorDeConversacionesActivado = true;
        } else {
          this.solicitarDV(pedido);
        }
      });
    } else {
      this.solicitarDV(pedido);
    }
  }

  enlazarConversacionConPedido(conversacion) {
    this.id_conversacion = conversacion._id;
    this.id_pedidoDvSeleccionado = this.pedidoDvSeleccionado._id;

    this.selectorDeConversacionesActivado = false;

    this.solicitarDV(this.pedidoDvSeleccionado);
  }

  //Adquiere el pedido DV desde el que se ha hecho click en el botón de adquirir
  public async solicitarDV(pedido) {
    this.appService.pedidoSeleccionado = pedido;
    // Añadir confirmación previa
    const modal = await this.appService.dialog({
      textoPrincipal: "¿ADQUIRIR SOLICITUD VIRTUAL?",
      btnConfirm: "Adquirir",
      btnCancel: "Cancelar",
    });
    await modal.present().then();
    await modal.onDidDismiss().then((value) => {
      if (value.data != null && value.data.aceptar) {
        if (this.id_conversacion && this.id_pedidoDvSeleccionado) {
          this.appService.pedidoSeleccionado.id_conversacion = this.id_conversacion;
          this.appService.conversacionAdquirida(this.id_conversacion, this.id_pedidoDvSeleccionado).subscribe();
        }
        this.appService.adquirirPedidoDV().subscribe(
          (valuePedido) => {
            if (valuePedido != null) {
              valuePedido.disenado = this.appService.userLogin;
              const pos = this.appService.pedidos.findIndex((p) => p._id === valuePedido._id);
              if (pos === -1) {
                this.appService.pedidos.push(new Pedido(valuePedido));
              } else {
                this.appService.pedidos[pos] = new Pedido(valuePedido);
              }
              // El correo de confirmación al cliente se manda desde el backend al usar appService.adquirirPedidoDV()
              // Actualizamos los datos del gestor de pedidos una vez adquirido
              this.appService.numPedidosDV--;
              this.appService.cambiarPedidosMostrar("diseñadorVirtual");
              this.appService.seleccionarPedido(pedido._id);
              this.cerrar();
            } else {
              console.log("Error de respuesta del backend");
              this.snackBar.open("Error de conexión con el backend.", "ok", {
                duration: 2000,
              });
            }
          },
          (error) => {
            console.error(error);
            this.snackBar.open(error, "ok", { duration: 2000 });
          }
        );
      }
    });
  }

  public cerrar(): void {
    this.modalController.dismiss().then();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { NotificacionesContainerPage } from './notificaciones-container.page';
import {MatIconModule} from "@angular/material/icon";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        MatIconModule
    ],
  exports: [
    NotificacionesContainerPage
  ],
  declarations: [NotificacionesContainerPage]
})
export class NotificacionesContainerPageModule {}

<div class="cabecera-modal">
  <span>{{textoPrincipal}}</span>
  <mat-icon class="cerrar-modal" (click)="cancelar()">close</mat-icon>
</div>

<div class="ion-padding">
  <div class="w-100">
    <div *ngIf="textoSecundario.length" class="ion-margin-bottom">
      <div *ngFor="let texto of textoSecundario" [ngClass]="texto.css">{{texto.texto}}</div>
    </div>
    <div *ngIf="controls.length" class="ion-margin-bottom" [formGroup]="formGroup">
      <div *ngFor="let control of controls">
        <mat-form-field *ngIf="isInput(control)" class="w-100">
          <mat-label >{{control.placeholder}}</mat-label>
          <input *ngIf="control.type != 'textarea'" type="{{control.type}}" [formControlName]="control.id" placeholder="{{control.placeholder}}" matInput autocomplete="off">
          <textarea *ngIf="control.type == 'textarea'" [formControlName]="control.id" placeholder="{{control.placeholder}}" matInput autocomplete="off" rows="3"></textarea>
        </mat-form-field>

        <mat-form-field *ngIf="isSelect(control)" class="w-100">
          <mat-label>{{control.placeholder}}</mat-label>
          <mat-select [formControlName]="control.id" placeholder="{{control.placeholder}}" multiple="{{control.multiple}}">
            <mat-option *ngFor="let option of control.options" [value]="option.value">{{option.text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <ion-button (click)="confirmar()">{{btnConfirm}}</ion-button>
      <ion-button color="medium" (click)="cancelar()">{{btnCancel}}</ion-button>
    </div>
  </div>
</div>

import { Component, OnInit } from "@angular/core";
import { AppService } from "../../servicios/app.service";
import { ModalController } from "@ionic/angular";
import { SelectorDvPage } from "src/app/pages/home/selector-dv/selector-dv.page";
import { SelectorDvsVencidosSinPuntosPage } from "../home/selector-dvs-vencidos-sin-puntos/selector-dvs-vencidos-sin-puntos.page";
import { not } from "@angular/compiler/src/output/output_ast";
@Component({
  selector: "app-notificaciones-container",
  templateUrl: "./notificaciones-container.page.html",
  styleUrls: ["./notificaciones-container.page.scss"],
})
export class NotificacionesContainerPage implements OnInit {
  public mostrar: boolean;
  public mostrarPedidosDVSinTotalPuntos: boolean;

  constructor(public appService: AppService, private modalController: ModalController) {
    this.mostrar = false;
  }

  ngOnInit() {
    if (this.appService.userLogin) {
      this.getNumPedidosDV();
      this.getPedidoDVSinPuntos();
    }
    setInterval(() => {
      this.getNumPedidosDV();
      this.getPedidoDVSinPuntos();
    }, 20 * 1000);
  }

  public getNumPedidosDV(): void {
    this.appService.getNumPedidosDV().subscribe(
      (num) => {
        this.appService.numPedidosDV = num;

        if (this.appService.numPedidosDV > 0 && !this.mostrar) {
          this.mostrarNotificacion();
        }
      },
      () => {
        this.appService.numPedidosDV = 0;
        if (this.mostrar) {
          this.ocultarNotificacion();
        }
      }
    );
  }

  public getPedidoDVSinPuntos(): void {
    if (
      this.appService.pedidosDVSinIndicarPuntosConFechaDeContactoSuperiorAHoy &&
      this.appService.pedidosDVSinIndicarPuntosConFechaDeContactoSuperiorAHoy.length > 0
    ) {
      this.mostrarNotificacionPedidoDVSinPuntos();
    } else {
      this.ocultarNotificacionPedidoDVSinPuntos();
    }
  }

  public mostrarNotificacion(): void {
    this.mostrar = true;
  }

  public mostrarNotificacionPedidoDVSinPuntos(): void {
    this.mostrarPedidosDVSinTotalPuntos = true;
  }

  public ocultarNotificacion(): void {
    const notificacion = document.getElementById("notificacion") as HTMLDivElement;
    notificacion.classList.add("notificacion-ocultar");
    setTimeout(() => {
      this.mostrar = false;
    }, 730);
  }

  public ocultarNotificacionPedidoDVSinPuntos(): void {
    const notificacion = document.getElementById("notificacionPedidosDVSinTotalPuntos") as HTMLDivElement;
    if (notificacion) {
      notificacion.classList.add("notificacion-ocultar");
    }
    setTimeout(() => {
      this.mostrarPedidosDVSinTotalPuntos = false;
    }, 730);
  }

  public async abrirSelectorDV() {
    // this.blur = true;
    const modal = await this.modalController.create({
      component: SelectorDvPage,
      cssClass: "ayuda-modal .modal-wrapper",
    });

    await modal.present().then();

    await modal.onDidDismiss().then(() => {
      // this.blur = false;
    });
  }

  public async abrirSelectorDVPedidoDVSinPuntos() {
    // this.blur = true;
    const modal = await this.modalController.create({
      component: SelectorDvsVencidosSinPuntosPage,
      cssClass: "ayuda-modal .modal-wrapper",
    });

    await modal.present().then();

    await modal.onDidDismiss().then(() => {
      // this.blur = false;
    });
  }
}
